export const columns = [
// {
//     title: 'ID',
//     dataIndex: 'id',
//     key: 'id',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    /*width:150,*/
    align:'center',
    ellipsis:true,
    width: 80,
    scopedSlots: { customRender: 'index'}
},
{
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    /*width:150,*/
    align:'center',
    // ellipsis:true
},
  {
    title: '图片',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    /*width:150,*/
    ellipsis: true,
    align: 'center',
    scopedSlots: {customRender: 'imageUrl'}
  },
// {
//     title: '描述',
//     dataIndex: 'description',
//     key: 'description',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
{
    title: '分类',
    dataIndex: 'category',
    key: 'category',
    /*width:150,*/
    align:'center',
    // ellipsis:true
},
{
    title: '标签',
    dataIndex: 'tag',
    key: 'tag',
    /*width:150,*/
    align:'center',
    // ellipsis:true
},
{
    title: '状态',
    dataIndex: 'flagUp',
    key: 'flagUp',
    /*width:150,*/
    align:'center',
    width: 100,
    // ellipsis:true,
    scopedSlots: { customRender: 'flagUp'}
},
// {
//     title: '是否删除',
//     dataIndex: 'flagDel',
//     key: 'flagDel',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '是否置顶',
//     dataIndex: 'flagTop',
//     key: 'flagTop',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '排序',
//     dataIndex: 'sort',
//     key: 'sort',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
{
    title: '收藏',
    dataIndex: 'collectionNum',
    key: 'collectionNum',
    /*width:150,*/
    align:'center',
    width: 100,
    // ellipsis:true
},
{
    title: '分享',
    dataIndex: 'shareNum',
    key: 'shareNum',
    /*width:150,*/
    align:'center',
    width: 100,
    // ellipsis:true
},
// {
//     title: '详情',
//     dataIndex: 'detail',
//     key: 'detail',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '创建人',
//     dataIndex: 'createUser',
//     key: 'createUser',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '创建人id',
//     dataIndex: 'createUserId',
//     key: 'createUserId',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
{
    title: '发布时间',
    dataIndex: 'releaseTime',
    key: 'releaseTime',
    /*width:150,*/
    align:'center',
    // ellipsis:true
},
// {
//     title: '操作',
//     key: 'operation',
//     fixed: 'right',
//     align: 'center',
//     width: 160,
//     scopedSlots: { customRender: 'action' },
//   },
// {
//     title: '修改人',
//     dataIndex: 'modifyUser',
//     key: 'modifyUser',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '修改人id',
//     dataIndex: 'modifyUserId',
//     key: 'modifyUserId',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '修改时间',
//     dataIndex: 'modifyTime',
//     key: 'modifyTime',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
// {
//     title: '版本号',
//     dataIndex: 'version',
//     key: 'version',
//     /*width:150,*/
//     align:'center',
    // ellipsis:true
// },
]

export const Pxcolumns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        /*width:150,*/
        align:'center',
        // ellipsis:true,
        width: 80,
        scopedSlots: { customRender: 'index'}
    },
    {
        title: '栏目名称',
        dataIndex: 'tagName',
        key: 'tagName',
        width:200,
        align:'center',
        // ellipsis:true
    },
    {
        title: 'logo',
        dataIndex: 'tagLogo',
        key: 'tagLogo',
        width:100,
        align:'center',
        // ellipsis:true,
        scopedSlots: { customRender: 'logo'}
    },
    {
        title: '是否启用',
        dataIndex: 'flagEnable',
        key: 'flagEnable',
        width: 100,
        align:'center',
        // ellipsis:true,
        scopedSlots: { customRender: 'flagEnable'}
    },
    {
        title: '描述',
        dataIndex: 'tagDesc',
        key: 'tagDesc',
        /*width:150,*/
        align:'center',
        // width: 100,
        // ellipsis:true
    },
    {
        title: '操作时间',
        dataIndex: 'modifyTime',
        key: 'modifyTime',
        /*width:150,*/
        align:'center',
        width: 150,
        // ellipsis:true
    },
    ]
    