<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-20 17:38:45
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-26 15:47:53
-->
<template>
  <div class="add_files_page">
    <a-modal v-model="visible" :maskClosable="false" title="新增" :width="1000" @ok="onConfirm">
      <div v-if="handle == 'bid'">
        <a-form-model
          ref="form"
          :model="rowData"
          labelAlign="right"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
        >
          <a-form-model-item label="类型" :autoLink="false" ref="test">
            <a-select v-model="type" labelInValue @change="handleChange">
              <a-select-option :value="item.id" v-for="(item, index) in filesList" :key="index">
                {{ item.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="文件名" prop="name">
            <a-input v-model="rowData.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="文件" prop="url">
            <QiniuUpload type="file" :value.sync="rowData.url"></QiniuUpload>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div v-if="handle == 'sample' || handle == 'article_file'">
        <a-form-model
          ref="form"
          :model="rowData"
          labelAlign="right"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
        >
          <a-form-model-item label="文件名" prop="name">
            <a-input v-model="rowData.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="文件" prop="url">
            <QiniuUpload type="file" :value.sync="rowData.url"></QiniuUpload>
          </a-form-model-item>
        </a-form-model>
      </div>

      <div v-if="handle == 'examining_report'">
        <a-form-model
          ref="form"
          :model="rowData"
          labelAlign="right"
          :rules="formRule"
          layout="horizontal"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
        >
          <a-form-model-item label="文件名" prop="name">
            <a-input v-model="rowData.name"></a-input>
          </a-form-model-item>
          <a-form-model-item label="文件" prop="url">
            <QiniuUpload type="file" :value.sync="rowData.url"></QiniuUpload>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'add_files_page',
  data() {
    return {
      visible: false,
      filesList: [],
      rowData: {},
      type: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入文件名', trigger: 'blur' }],
        url: [{ required: true, message: '请上传文件', trigger: 'change' }],
      },
      handle: ''
    }
  },

  computed: {},

  created() {},

  methods: {
    show(name) {
      this.rowData = {}
      this.type = {}
      this.visible = true
      this.handle = name
      this.getList()
    },


    getList() {
      this.axios.get('/api/base/system/dictionary/selectByCode/' + '投标文件类型').then((res) => {
        // 获取字典仓库
        this.filesList = res.body
      })
    },

    handleChange(val) {
      this.$refs.test.onFieldChange()
      this.rowData.typeId = val.key
      this.rowData.typeName = val.label
    },

    onConfirm() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        if(this.handle == 'sample') {
          this.$emit('getSampleList', this.rowData)
        }

        if(this.handle == 'bid') {
          this.$emit('getItem', this.rowData)
        }

        if(this.handle == 'examining_report') {
          this.$emit('getExaminingList', this.rowData)
        }

        if(this.handle == 'article_file') {
          this.$emit('getFileList', this.rowData)
        }
        this.visible = false
      })
    },
  },
}
</script>

<style lang='scss' scoped>
</style>