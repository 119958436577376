/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-10 15:54:16
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-11 10:53:31
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listArticle = params => axios({
    url: '/api/base/article/article/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})


export const indexListArticle = params => axios({
  url: '/api/base/article/articleIndexRecommendRecord/list',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})



export const listIndexArticle = params => axios({
  url: '/api/base/article/article/list',
  method: 'get',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const collectListArticle = params => axios({
    url: '/api/base/article/article/list/collectCenter',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addArticle = params => axios({
    url: '/api/base/article/article/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const addArticleTag = params => axios({
    url: '/api/base/article/articleTag/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editArticle = params => axios({
    url: '/api/base/article/article/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editArticleTag = params => axios({
    url: '/api/base/article/articleTag/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const delArticle = params => axios({
    url:'/api/base/article/article/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const delArticleIndex = params => axios({
  url:'/api/base/article/articleIndexRecommendRecord/del/' + params,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})

export const delArticleTag = params => axios({
    url:'/api/base/article/articleTag/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdArticle = params => axios({
    url: '/api/base/article/article/selectById/' + params,


    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const getArticleCategory = () => axios({
    url: '/api/base/article/articleCategory/tree/list?enable=true',
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const getArticleTag = params => axios({
    url: '/api/base/article/articleTag/list/all?cateName='+params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const getCategoryParentIds = params => axios({
    url: '/api/base/article/articleCategory/parentIds/'+params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const getIdByType = params => axios({
    url: '/api/base/article/articleCategory/getIdByType?type='+params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const listArticlePxColumn = params => axios({
    url: '/api/base/article/article/training/column',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdArticleTagKonwLedge = params => axios({
    url: '/api/base/article/articleTag/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})