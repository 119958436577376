<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    width="70%"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 3 }"
      :wrapper-col="{ span: 21 }"
    >
      <a-row :gutter="4" v-if="isAllStatus">
        <!-- <a-col :md="12">
          <a-form-model-item
            label="分类"
            prop="articleCategoryId"
            :autoLink="false"
            ref="test"
            :labelCol="{ span: 6, offset: 0 }"
            :wrapperCol="{ span: 14, offset: 0 }"
          >
            <a-cascader
              v-model="rowData.articleCategoryId"
              :field-names="{ label: 'name', value: 'id', children: 'children' }"
              :options="category"
              :diabled="true"
              placeholder="请选择"
              @change="onCategoryChange"
            />
          </a-form-model-item>
        </a-col> -->
        <a-col :md="12">
          <a-form-model-item label="标签" prop="tagIdList" mode="multiple" :autoLink="false" ref="test" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select placeholder="请选择" v-model="rowData.tagIdList" @change="onTagChange">
              <a-select-option :value="item.id" v-for="(item, index) in tag" :key="index">{{
                item.tag_name
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="标题" prop="title">
            <a-input v-model="rowData.title" placeholder="标题"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="描述" prop="description">
            <a-textarea v-model="rowData.description" placeholder="请输入" :auto-size="{ minRows: 3, maxRows: 21 }" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="12">
          <a-form-model-item label="状态" prop="flagUp" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-radio-group v-model="rowData.flagUp" @change="onChange">
              <a-radio :value="1"> 上架 </a-radio>
              <a-radio :value="2"> 下架 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :md="12">
          <a-form-model-item label="置顶" prop="(flagTop" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-switch v-model="rowData.flagTop" checked-children="启用" un-checked-children="禁用" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="排序" prop="sort">
            <a-input v-model="rowData.sort" placeholder="排序" style="width: 100px"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="发布时间" prop="">
            <DatePicker :hasTime="true" :startTime.sync="rowData.releaseTime" style="width: 200px"></DatePicker>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="4">
        <a-col :md="24">
          <a-form-model-item label="列表主图" prop="(imageList">
            <QiniuUpload type="image" :value.sync="imageUrl" :size="9"></QiniuUpload>
            图片尺寸：321×321px 或等比例
          </a-form-model-item>
        </a-col>
      </a-row>

<!--      <a-row v-if="tagName == '装修手册'" :gutter="4">-->
<!--        <a-col :md="24">-->
<!--          <a-form-model-item label="文件" prop="fileList">-->
<!--            <QiniuUpload type="file" :value.sync="fileUrl" :size="9"></QiniuUpload>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row :gutter="4">-->
<!--        <a-col :md="24">-->
<!--          <a-form-model-item label="详情" prop="detail">-->
<!--            <Tinymce :value.sync="rowData.detail"></Tinymce>-->
<!--          </a-form-model-item>-->
<!--        </a-col>-->
<!--      </a-row>-->

      <a-tabs @change="callback">
        <a-tab-pane key="1" tab="详情">
          <Tinymce :value.sync="rowData.detail" :tkey="tkey" ref="Tinymce"></Tinymce>
        </a-tab-pane>
        <a-tab-pane key="2" tab="文件" v-if="tagName == '装修手册'">
          <div class="btn">
            <a-button type="primary" @click="onShowFiles('article_file')">新增</a-button>
          </div>
          <a-table :data-source="fileList" bordered v-if="fileList.length > 0">
            <a-table-column title="文件名" data-index="name" align="center"></a-table-column>
            <a-table-column title="文件链接" align="center">
              <template slot-scope="row">
                <a :href="row.url" target="_blank" class="buttonText">{{row.url}}</a>
              </template>
            </a-table-column>
            <a-table-column title="操作" data-index="" align="center">
              <template slot-scope="row">
                <a @click="onDelFiles(row)">删除</a>
              </template>
            </a-table-column>
          </a-table>
        </a-tab-pane>
      </a-tabs>

    </a-form-model>
    <AddFilesPage ref="AddFilesPage" @getFileList="getFileList"></AddFilesPage>
  </a-modal>
</template>

<script>
import {
  editArticle,
  selectByIdArticle,
  addArticle,
  getArticleCategory,
  getArticleTag,
  getCategoryParentIds,
} from '../api/ArticleApi'
import Tinymce from '../../../components/Editor/Tinymce'
import AddFilesPage from '@/views/product_center/components/AddFilesPage.vue'
export default {
  components: {
    Tinymce,
    AddFilesPage,
  },
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      fileList: [],
      tkey: 0,
      // 表单验证
      formRule: {
        articleCategoryId: [{ required: true, message: '请选择分类', trigger: 'change' }],
        tagIdList: [{ type: 'array', required: true, message: '请选择标签', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        description: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        flagUp: [{ required: true, message: '请选择状态', trigger: 'change' }],
        sort: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        detail: [{ required: true, message: '请输入详情', trigger: 'blur' }],
      },
      category: [],
      tag: [],
      imageUrl: '',
      // fileUrl: '',
      isAllStatus: false,
      cateName: '',
      tagName: ''
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle, isAllStatus, cateName, tagName) {
      this.imageUrl = ''
      // this.fileUrl = ''
      this.handle = handle
      this.rowData = {}
      this.visible = true
      this.cateName = cateName
      this.tagName = tagName
      this.fileList = []
      if(isAllStatus){
        this.isAllStatus = isAllStatus
      }
      if (handle === 'add') {
        this.$set(this.rowData, 'flagUp', 1)
        this.$set(this.rowData, 'flagTop', false)
      }

      if (handle === 'edit') {
        selectByIdArticle(row.id).then((res) => {
          this.rowData = res.body
          let imageList = this.rowData.imageList
          let imageUrl = ''
          // let fileList = this.rowData.fileList
          // let fileUrl = ''
          for (let image = 0; image < imageList.length; image++) {
            if (image != 0) {
              imageUrl += ','
            }
            imageUrl += imageList[image]
          }
          this.imageUrl = imageUrl
          // for (let file = 0; file < fileList.length; file++) {
          //   if (file != 0) {
          //     fileUrl += ','
          //   }
          //   fileUrl += fileList[file]
          // }
          // this.fileUrl = fileUrl
          this.fileList = this.rowData.fileList
          // if(this.isAllStatus){
          //   getCategoryParentIds(this.rowData.articleCategoryId).then((res) => {
          //     this.rowData.articleCategoryId = res.body
          //   })
          // }
        })
      }

      getArticleCategory().then((res) => {
        this.category = res.body
      })

      getArticleTag(this.cateName).then((res) => {
        this.tag = res.body
      })
    },
    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        if (this.imageUrl) {
          let imageUrlArr = this.imageUrl.split(',')
          this.rowData.imageList = imageUrlArr
        } else {
          this.rowData.imageList = []
        }

        // if (this.fileUrl) {
        //   let fileUrlArr = this.fileUrl.split(',')
        //   this.rowData.fileList = fileUrlArr
        // } else {
        //   this.rowData.fileList = []
        // }
        // if(this.isAllStatus){
        //   this.rowData.articleCategoryId = this.rowData.articleCategoryId[this.rowData.articleCategoryId.length - 1]
        // }
        this.rowData.fileList = this.fileList
        this.rowData.cateName = this.cateName
        this.rowData.tagName = this.tagName
        const res = this.handle === 'add' ? await addArticle(this.rowData) : await editArticle(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
          this.imageUrl = ''
          // this.fileUrl = ''
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    onCategoryChange(categoryId) {},
    onTagChange(tagId) {
      console.log(tagId)
      const arr = new Array();
      arr.push(tagId)
      this.rowData.tagIdList = arr
    },
    onChange(value) {},
    onTopChange(value) {
      console.log(value)
    },

    onShowFiles(name) {
      this.$refs.AddFilesPage.show(name)
    },

    onDelFiles(row) {
      this.fileList.splice(
        this.fileList.findIndex((e) => {
          return e.typeId == row.typeId
        }),
        1
      )
    },

    getFileList(item) {
      this.fileList.push(item)
    },

    callback(key) {
      // console.log(this.$refs)
      // this.$refs.Tinymce.onActivated()
      this.tkey++
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.required_fields:before {
  content: '*';
  color: red;
  margin-right: 4px;
  margin-bottom: -28px;
  margin-left: 63px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

/*富文本编辑器*/
.text-editor-box {
  margin: 0 20px;
}

.form_sub_btn {
  margin: 28px 20px 0;
}
</style>
